const theme = {
  lightShade: '#F9F7F6',
  lightAccent: '#70c1b3',
  mainBrandColor: '#50514f',
  darkAccent: '#f25f5c',
  darkShade: '#ffe066',
  lapiLazuli: '#247ba0',
}

export default theme
