import './layout.css'

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import theme from '../theme'
import { createGlobalStyle } from 'styled-components'
import urlImage from '../images/avatar.jpg'

const GlobalStyle = createGlobalStyle`
  html,
  body,
  textarea {
    font-family: 'Lato', serif;
  }

  body{
    padding-top: 50px;
    background-color: #F9F7F6;
  }

  p{
    margin-bottom: 10px;
    line-height: 1.2;
    font-size: 17px;
  }

   ul{
     padding-left: 10px;
   }

   li{
     margin-bottom: 10px;
     font-size: 17px;
     line-height: 1.2;
   }
   
`

const content =
  'Myriam Boulaire, Coordonnatrice SPS niveau 1 (prévention des risques, accessibilité handicapé) , j’interviens sur Paris et sa région et sur le Morbihan.'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content,
            },
            {
              property: 'og:title',
              content: data.site.siteMetadata.titlee,
            },
            {
              property: 'og:description',
              content: content,
            },
            {
              property: 'og:site_name',
              content: 'Myriam Boulaire',
            },
            {
              property: 'og:type',
              content: 'website',
            },
            {
              property: 'og:image',
              content: urlImage,
            },

            { property: 'og:url', content: 'https://myriamboulaire.com/' },
          ]}
        >
          <html lang="fr" />
        </Helmet>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <div>{children}</div>
        </ThemeProvider>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.oneOf(
    PropTypes.node.isRequired,
    PropTypes.arrayOf(PropTypes.node)
  ),
}

export default Layout
